<template>
  <div class="by-code-login">
    <el-form
      :model="ruleForm1"
      status-icon
      :rules="rules"
      ref="ruleForm1"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item prop="account" class="father-box">
        <el-input
          v-model="ruleForm1.account"
          :placeholder="languagePackage[language].loginPage_phone"
        ></el-input>

        <i class="account-icon el-icon-phone"></i>
      </el-form-item>

      <el-form-item prop="code" class="father-box">
        <div class="auth-code">
          <el-input
            v-model="ruleForm1.code"
            :placeholder="languagePackage[language].loginPage_phone_duanxin"
          ></el-input>
          <el-button @click="getAuthCode" :loading="loading">
            {{
              loading
                ? countDown + "s"
                : languagePackage[language].loginPage_get_phone_duanxin
            }}
          </el-button>
        </div>
      </el-form-item>

      <div class="login-operate">
        <el-checkbox v-model="checked">{{
          languagePackage[language].loginPage_jizhuwo
        }}</el-checkbox>
        <el-button class="forget-pwd" @click="forgetPwd">{{
          languagePackage[language].loginPage_forget_password
        }}</el-button>
      </div>

      <div class="login-bottom">
        <el-button type="primary" @click="submitTel('ruleForm1')">
          {{ languagePackage[language].loginPage_submit }}
        </el-button>
        <el-button type="primary" @click="register">
          {{ languagePackage[language].loginPage_register }}
        </el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import languagePackage from "@/languagePackage/index.js";

import { checkAccount, checkPassword } from "@/utils/tools";
import {
  do_login_by_phone_yzm,
  do_sendmsg,
} from "@/api/dologin.js";
import md5 from "md5";
export default {
  data() {
    return {
      languagePackage,
      loading: false,
      checked: true,
      countDown: 60, //倒计时

      ruleForm1: {
        account: "",
        code: "",
      },
      rules: {
        pass: [{ validator: checkPassword, trigger: "blur" }],
        account: [{ validator: checkAccount, trigger: "blur" }],
        code: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    language() {
      return this.$store.getters.language;
    },
  },
  methods: {
    //忘记密码
    forgetPwd() {
      this.$router.push("/forget");
    },

    //注册
    register() {
      this.$router.push("/register");
    },

    //获取验证码
    getAuthCode() {
      let phone = this.ruleForm1.account; //手机号
      if (!phone) {
        this.$notify({
          type: "error",
          message: "请输入手机号",
        });
        return;
      }
      let time_stamp = Math.floor(new Date().getTime() / 1000) + ""; //时间戳秒级
      let sign = md5(md5(phone + "jianzhan" + time_stamp)); //数据签名
      let scene = "登录";
      let data = { phone, time_stamp, sign, scene };

      // 获取验证码接口
      do_sendmsg(data)
        .then((res) => {
          console.log(res);
          this.loading = true;
          this.countDownFn();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    },

    //短信登录提交
    submitTel(ruleForm) {
      this.$refs[ruleForm].validate((valid) => {
        //短信登录
        if (valid) {
          this.isHint = false;
          let phone = this.ruleForm1.account;
          let yzm = "1234";
          let data = { phone, yzm };

          // 手机 + 验证码登录接口
          do_login_by_phone_yzm(data)
            .then((res) => {
              console.log("---res---", res);
              if (res.code === 200) this.do200(res);
            })
            .catch((err) => {
              console.log("---err---", err);
            })
            .finally(() => {
              console.log("---finally---");
            });
          return;
        }
      });
    },

    do200(res) {
      let user = {
        user_id: res.data.user_id,
        user_token: res.data.user_token,
        head_img: res.data.head_img,
        nickname: res.data.nickname,
      };
      this.$store.commit("set_user_info", user);

      this.$notify({
        type: "success",
        message: "登录成功",
      });
      if (this.$route.query.old) {
        this.$router.push(this.$route.query.old);
      } else {
        this.$router.push("/");
      }
    },

    // 倒计时
    countDownFn() {
      let timer = window.setInterval(() => {
        this.countDown--;
        this.loading = true;
        if (this.countDown < 0) {
          this.countDown = 60;
          this.loading = false;
          window.clearInterval(timer);
        }
      }, 1000);
    },
  },
};
</script>

<style lang="less" scoped>
.by-code-login {
  position: relative;
  /deep/ .el-tabs__nav-wrap::after {
    background: #fff;
  }
  .account-icon {
    position: absolute;
    top: 12px;
    left: 10px;
    font-size: 18px;
  }

  /deep/.el-form-item__content {
    margin-left: 0 !important;
  }

  .auth-code {
    display: flex;
    margin-top: 20px;

    /deep/.el-input__inner {
      padding-left: 15px;
      width: 220px;
      height: 42px;
    }
    .el-input {
      width: 220px;
    }
    .el-button {
      width: 102px;
      padding: 0px;
      color: #299ffe;
      border: 1px solid #299ffe;
      font-size: 14px;
    }
    /deep/.el-button [class*="el-icon-"] + span {
      margin-left: -2px;
      font-size: 12px;
    }
  }

  .login-operate .el-checkbox {
    height: 40px;
    line-height: 40px;
  }
  .login-operate,
  .login-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
    height: 42px;
  }
  .login-bottom {
    > button {
      width: 130px;
      height: 42px;
    }
  }
  .forget-pwd {
    border: 0;
    outline: none;
  }
  .forget-pwd:hover {
    background-color: transparent;
  }

  .el-form-item {
    margin-bottom: 0;
  }
  .el-input__inner {
    padding-left: 0;
  }

  /deep/.el-input__inner {
    height: 42px;
    padding-left: 32px;
  }
}
</style>