<template>
  <div>
    <moban>
      <div class="loginPage">
        <h2 class="title">
          <span>{{ $lang("loginPage_login_title") }}</span>
          <i class="close el-icon-close" @click="closeLogin"></i>
        </h2>

        <div class="center">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <!-- 手机号+密码 登录 -->
            <el-tab-pane
              :label="$lang('loginPage_by_password')"
              name="pwdLogin"
              class="pwdLogin"
            >
              <byPwdLogin></byPwdLogin>
            </el-tab-pane>

            <!-- 手机号+验证码 登录 -->
            <el-tab-pane
              :label="$lang('loginPage_by_code')"
              name="telLogin"
              class="telLogin"
            >
              <byCodeLogin></byCodeLogin>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </moban>
  </div>
</template>

<script>
import moban from "@/components/indexIndexIndex/moban.vue";
import byPwdLogin from "./c/byPwdLogin.vue";
import byCodeLogin from "./c/byCodeLogin.vue";

export default {
  components: {
    moban,
    byPwdLogin,
    byCodeLogin,
  },
  data() {
    return {
      activeName: "pwdLogin",
    };
  },
  computed: {
    language() {
      return this.$store.getters.language;
    },
  },
  methods: {
    handleClick() {
      console.log(this.activeName);
    },

    //关闭
    closeLogin() {
      if (window.history.length <= 1) {
        this.$router.push({ path: "/" });
        return false;
      } else {
        this.$router.back();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.loginPage {
  width: 380px;
  height: 490px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: -7px 5px 4px 0px rgba(122, 122, 122, 0.14);

  /deep/ .el-tabs__nav-wrap::after {
    background: #fff;
  }

  .title {
    font-size: 24px;
    color: #333;
    text-align: center;
    position: relative;
    height: 60px;
    border-bottom: 1px solid #eee;
    margin-top: 30px;
  }
  .close {
    position: absolute;
    right: 30px;
    top: 4px;
    font-size: 24px;
    color: #666;
    cursor: pointer;
  }
  .center {
    padding: 20px 30px;
    .mb20 {
      margin-bottom: 20px;
    }

    /deep/.el-form-item__content {
      margin-left: 0 !important;
    }

    .login-operate .el-checkbox {
      height: 40px;
      line-height: 40px;
    }
    .login-bottom {
      > button {
        width: 130px;
        height: 42px;
      }
    }
    .forget-pwd {
      border: 0;
      outline: none;
    }
    .forget-pwd:hover {
      background-color: transparent;
    }
  }
}
</style>