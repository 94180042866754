<template>
  <div class="by-pwd-login pwdLogin">
    <el-form
      :model="ruleForm"
      status-icon
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item prop="account" class="father-box mb20">
        <el-input
          v-model="ruleForm.account"
          :placeholder="languagePackage[language].loginPage_phone"
        ></el-input>
        <i class="account-icon el-icon-phone"></i>
      </el-form-item>
      <el-form-item>
        <el-form-item prop="pass" class="father-box">
          <el-input
            show-password
            v-model="ruleForm.pass"
            autocomplete="off"
            :placeholder="languagePackage[language].loginPage_password"
          ></el-input>

          <i class="account-icon el-icon-lock"></i>
        </el-form-item>
        <div class="login-operate">
          <el-checkbox v-model="checked">{{
            languagePackage[language].loginPage_jizhumima
          }}</el-checkbox>
          <el-button class="forget-pwd" @click="forgetPwd">{{
            languagePackage[language].loginPage_forget_password
          }}</el-button>
        </div>
        <div class="login-bottom">
          <el-button
            :loading="logining"
            type="primary"
            @click="submitPwd('ruleForm')"
          >
            {{ languagePackage[language].loginPage_submit }}
          </el-button>
          <el-button type="primary" @click="register">
            {{ languagePackage[language].loginPage_register }}
          </el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { do_login_by_phone_password } from "@/api/dologin.js";
import { checkAccount, checkPassword } from "@/utils/tools";
import languagePackage from "@/languagePackage/index.js";

export default {
  data() {
    return {
      languagePackage,
      // 表单数据
      ruleForm: {
        pass: "",
        account: "",
      },
      //表单验证
      rules: {
        pass: [{ validator: checkPassword, trigger: "blur" }],
        account: [{ validator: checkAccount, trigger: "blur" }],
        code: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
      },
      //记住密码
      checked: true,
      //loading
      logining: false,
    };
  },
  computed: {
    language() {
      return this.$store.getters.language;
    },
  },
  methods: {
    //密码登录提交
    submitPwd(ruleForm) {
      this.$refs[ruleForm].validate((valid) => {
        // 密码登录
        if (valid) {
          this.isHint = false;
          let phone = this.ruleForm.account;
          let password = this.ruleForm.pass;
          let data = { phone, password };
          this.logining = true;
          // 手机 + 密码登录接口

          do_login_by_phone_password(data)
            .then((res) => {
              console.log("---res---", res);
              if (res.code === 200) this.do200(res);
            })
            .catch((err) => {
              console.log("---err---", err);
            })
            .finally(() => {
              console.log("---finally---");
              this.logining = false;
            });
          return;
        }
      });
    },
    //忘记密码
    forgetPwd() {
      this.$router.push("/forget");
    },
    //注册
    register() {
      this.$router.push("/register");
    },

    do200(res) {
      let user = {
        user_id: res.data.user_id,
        user_token: res.data.user_token,
        head_img: res.data.head_img,
        nickname: res.data.nickname,
      };
      this.$store.commit("set_user_info", user);

      this.$notify({
        type: "success",
        message: "登录成功",
      });
      if (this.$route.query.old) {
        this.$router.push(this.$route.query.old);
      } else {
        this.$router.push("/");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.by-pwd-login {
  position: relative;
  /deep/ .el-tabs__nav-wrap::after {
    background: #fff;
  }
  .mb20 {
    margin-bottom: 20px;
  }
  .login-module {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    width: 380px;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 8px;

    .login-title {
      text-align: center;
      color: #333;
      font-size: 24px;
      height: 60px;
    }
  }
  .login-operate,
  .login-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
    height: 42px;
    /deep/.el-checkbox__input {
      line-height: 0;
      width: 14px;
      height: 14px;
    }
  }
  .login-operate .el-checkbox {
    height: 40px;
    line-height: 40px;
  }
  .login-bottom {
    > button {
      width: 130px;
      height: 42px;
    }
  }
  .forget-pwd {
    border: 0;
    outline: none;
  }
  .forget-pwd:hover {
    background-color: transparent;
  }

  .telLogin .el-form-item {
    margin-bottom: 0;
  }
  .telLogin .el-input__inner {
    padding-left: 0;
  }

  .account-icon {
    position: absolute;
    top: 12px;
    left: 10px;
    font-size: 18px;
  }
  /deep/.el-input__inner {
    padding-left: 32px;
  }

  .telLogin {
    position: relative;
    .account-icon {
      position: absolute;
      top: 12px;
      left: 10px;
      font-size: 18px;
    }
    /deep/.el-input__inner {
      padding-left: 32px;
    }
  }
  /deep/.el-input__inner {
    height: 42px;
  }
}
</style>