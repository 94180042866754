import request from '@/utils/request'
//密码登录
export const do_login_by_phone_password = (data) => {
    let url = "/index.php/api/v1_0_0.login/do_login_by_phone_password";
    return request.post(url, data)
}
//短信登录
export const do_login_by_phone_yzm = (data) => {
    let url = "/index.php/api/v1_0_0.login/do_login_by_phone_yzm";
    return request.post(url, data)
}
//发送验证码
export const do_sendmsg = (data) => {
    let url = "/index.php/api/v1_0_0.sendmsg/do_sendmsg";
    return request.post(url, data)
}
//注册
export const do_register = (data) => {
    let url = "/index.php/api/v1_0_0.register/do_register";
    return request.post(url, data)
}
//短信重置密码
export const do_reset_passowrd = (data) => {
    let url = "/index.php/api/v1_0_0.forget/do_reset_passowrd";
    return request.post(url, data)
}