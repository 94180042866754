//验证账号
export const checkAccount = (rule, value, callback) => {
    if (!value) {
        callback(new Error("请输入账号"));
    } else if (
        !/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(
            value
        )
    ) {
        callback(new Error("手机号格式不正确"));
    } else {
        callback();
    }
};
//验证密码
export const checkPassword = (rule, value, callback) => {
    if (!value) {
        callback(new Error("请输入密码"));
    } else if (!/^[a-zA-Z0-9_]{6,16}$/.test(value)) {
        callback(new Error("字母、数字、下划线组成，6-16位"));
    } else {
        callback();
    }
};
//验证单个输入框
export const inputContent = (rule, value, callback) => {
    if (!value) {
        callback(new Error("请输入内容"));
    } else if (!/^[\u4E00-\u9FA5a-zA-Z]{3,12}$/.test(value)) {
        callback(new Error("中文、字母、下划线组成，3-12位"));
    } else {
        callback();
    }
};

//回到顶部
export const ScrollTop = (number = 0, time) => {
    if (!time) {
        document.body.scrollTop = document.documentElement.scrollTop = number;
        return number;
    }
    const spacingTime = 20; // 设置循环的间隔时间  值越小消耗性能越高
    let spacingInex = time / spacingTime; // 计算循环的次数
    let nowTop =
        document.body.scrollTop + document.documentElement.scrollTop; // 获取当前滚动条位置
    let everTop = (number - nowTop) / spacingInex; // 计算每次滑动的距离
    let scrollTimer = setInterval(() => {
        if (spacingInex > 0) {
            spacingInex--;
            ScrollTop((nowTop += everTop));
        } else {
            clearInterval(scrollTimer); // 清除计时器
        }
    }, spacingTime);
};
